import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import Lenis from '@studio-freight/lenis'

// 优化滚动
const lenis = new Lenis({
  lerp: 0.2,
  duration: 1.5,
})
window.lenis = lenis
function raf(time) {
  lenis.raf(time)
  requestAnimationFrame(raf)
}
requestAnimationFrame(raf)

import '@splidejs/splide/css'
const app = createApp(App)
import kmSize from './components/kmSize/index'
import kmSlideMenu from './components/kmSlideMenu/index'
import kmTab from './components/kmTab/index'
import kmIntrod from './components/kmIntrod/index'
import kmNumber from './components/kmNumber/index'

app.component('kmSize', kmSize)
app.component('kmSlideMenu', kmSlideMenu)
app.component('kmTab', kmTab)
app.component('kmIntrod', kmIntrod)
app.component('kmNumber', kmNumber)

app.use(router).mount('#app')
