<template>
  <div class="kl-footer">
    <kmSize>
      <div class="kl-bottom">
        <div class="kl-company">
          <div class="kl-company-name">
            <p class="light">珂蓝（重庆）生物医药科技有限责任公司</p>
            <p>中国美白祛斑一站式智能生产引领企业</p>
          </div>
        </div>
        <div class="kl-address">
          <div class="kl-menu">
            <span
              :class="{ light: item.active }"
              v-for="(item, index) in menu"
              :key="index"
              @click="navigateTo(item)"
              >{{ item.name }}</span
            >
          </div>
          <div class="kl-company-contact">
            <div class="tel">
              <img
                src="https://ke-meng.oss-cn-hangzhou.aliyuncs.com/kelanbm-web/index/tel.png"
                alt=""
              />
              <p>023-62592668</p>
            </div>
            <p>地址：重庆市巴南区木洞镇瑞翊路333号</p>
            <p>邮编：401320</p>
          </div>
        </div>

        <!-- <div class="kl-qrcode">
          <img
            src="https://ke-meng.oss-cn-hangzhou.aliyuncs.com/kelanbm-web/base/kl-qrcode.jpg"
            alt=""
          />
          <p>扫描二维码关注我们</p>
        </div> -->
      </div>
    </kmSize>
    <div class="kl-copyright">
      Kelan Biomedical Technology Co., LTD,
      珂蓝（重庆）生物医药科技有限责任公司,保留版权所有
    </div>
  </div>
</template>
<script>
import { scrollToTop } from '@/utils'
export default {
  data() {
    return {
      menu: [
        {
          name: '首页',
          id: 0,
          path: '/index',
          active: false,
        },
        {
          name: '关于我们',
          id: 1,
          path: '/about',
          active: false,
        },
        {
          name: '产品服务',
          id: 2,
          path: '/product',
          active: false,
        },
        {
          name: '技术领先',
          id: 3,
          path: '/design',
          active: false,
        },
        {
          name: '智能制造',
          id: 4,
          path: '/produce',
          active: false,
        },
        {
          name: '新闻资讯',
          id: 5,
          path: '/news',
          active: false,
        },
        {
          name: '加入珂蓝',
          id: 6,
          path: '/join',
          active: false,
        },
      ],
    }
  },
  watch: {
    $route: {
      handler(to) {
        this.menu.forEach((item) => {
          if (to.path.indexOf(item.path) >= 0) {
            item.active = true
          } else {
            item.active = false
          }
        })
      },
      immediate: true,
    },
  },
  methods: {
    navigateTo(item) {
      this.$router.push(item.path)
      scrollToTop()
    },
  },
}
</script>
<style lang="scss" scoped>
@import './pc.scss';
@import './ipad.scss';
@import './mobile.scss';
</style>
