<template>
  <div class="kl-slide-menu">
    <div>
      <div class="qrcode">
        <img
          class="img-qrcode"
          src="https://ke-meng.oss-cn-hangzhou.aliyuncs.com/kelanbm-web/base/img-qrcode.png?v=20230919"
        />
      </div>
      <img src="@/assets/imgs/base/icon-wx.png" />
    </div>
    <div id="kl-udesk-connect-btn">
      <img src="@/assets/imgs/base/icon-customer.png" />
    </div>
    <div @click="toTop">
      <img src="@/assets/imgs/base/icon-arrow-up.png" />
    </div>
    <!-- <div>
      <img
        src="https://ke-meng.oss-cn-hangzhou.aliyuncs.com/kelanbm-web/base/img-chart.png"
      />
      <span>在线客服</span>
    </div>
    <div>
      <img
        class="img-qrcode"
        src="https://ke-meng.oss-cn-hangzhou.aliyuncs.com/kelanbm-web/base/img-qrcode.png?v=20230919"
      />
      <span>客服微信</span>
    </div>
    <div class="kl-pinned" @click="toTop">
      <img
        src="https://ke-meng.oss-cn-hangzhou.aliyuncs.com/kelanbm-web/base/img-up-arrow.png"
      />
      <span>返回顶部</span>
    </div> -->
  </div>
</template>
<script>
import { scrollToTop } from '@/utils'
export default {
  mounted() {},
  methods: {
    toTop() {
      scrollToTop()
    },
  },
}
</script>
<style lang="scss" scoped>
@import './pc.scss';
@import './ipad.scss';
@import './mobile.scss';

// .kl-slide-menu {
//   position: fixed;
//   right: 114px;
//   top: 50%;

//   > div {
//     display: flex;
//     flex-direction: column;
//     background: #34353e;
//     color: white;
//     font-size: 14px;
//     align-items: center;
//     justify-content: center;
//     width: 110px;
//     box-sizing: border-box;
//     height: 110px;

//     &:nth-child(1) {
//       background: #0e80d7;
//     }

//     &:nth-child(2) {
//       border-bottom: 1px solid #cccccc;
//     }

//     img {
//       width: 77px;
//       height: auto;
//       margin-bottom: 2px;
//     }
//   }
// }
</style>
