<template>
  <div class="kl-introd">
    <div
      class="kl-introd-img kl-show-min"
      :class="{
        'kl-introd-run': isRun,
      }"
    >
      <img class="kl-trod-show" :src="config[name].url" alt="" mode="center" />
    </div>
    <div
      class="kl-introd-text kl-show-big"
      :class="{
        'kl-introd-run': isRun,
      }"
    >
      <div class="kl-introd-title">
        <p v-html="config[name].title1"></p>
        <!-- <p v-html="config[name].title2"></p> -->
      </div>
      <div class="kl-introd-border">
        <i></i>
      </div>
      <div class="kl-introd-desc" v-html="config[name].desc"></div>
      <div class="kl-introd-btn">
        <button @click="navigateTo">了解详情</button>
      </div>
    </div>
  </div>
</template>
<script>
import { apiListInfoByPage } from '@/api/API'
export default {
  data() {
    return {
      params: {
        pageNo: 1,
        pageSize: 1,
        type: '',
      },
      isRun: false,
      config: {
        industry: {},
        stipulate: {},
        enterprise: {},
        staff: {},
      },
      name: 'industry',
    }
  },
  props: {
    current: {
      type: String,
    },
  },
  watch: {
    current: {
      handler() {
        this.isRun = true

        setTimeout(() => {
          this.name = this.current
          this.isRun = false
          if (this.name == 'industry') {
            this.params.type = '19101'
          } else if (this.name == 'stipulate') {
            this.params.type = '19102'
          } else if (this.name == 'enterprise') {
            this.params.type = '19103'
          } else if (this.name == 'staff') {
            this.params.type = '19104'
          }

          if (Object.keys(this.config[this.name]).length == 0) {
            this.getListInfoByPage()
          }
        }, 500)
      },
      immediate: true,
    },
  },
  mounted() {},
  methods: {
    navigateTo() {
      let { id } = this.config[this.name]
      let params = {
        id,
        type: this.params.type,
        pageNo: 1,
      }
      console.log('params', params)
      this.$router.push({ path: '/news/detail', query: params })
    },
    getListInfoByPage() {
      apiListInfoByPage(this.params).then((res) => {
        const { errcode, data } = res
        if (errcode == 0) {
          let item = data.data[0] || {}
          let { title: title1, subTitle: desc, headPic: url, id } = item
          this.config[this.name] = { title1, desc, url, id }
        }
      })
    },
  },
}
</script>
<style lang="scss" scoped>
@import './pc.scss';
@import './ipad.scss';
@import './mobile.scss';
</style>
