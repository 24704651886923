<template>
  <div class="km-size" :style="{ maxWidth: width }">
    <slot></slot>
  </div>
</template>
<script>
export default {
  props: {
    width: {
      type: String,
      default: '1200px',
    },
    padding: {
      type: Boolean,
      default: true,
    },
  },
}
</script>
<style lang="scss" scoped>
.km-size {
  margin: 0 auto;
}
@media screen and (min-width: 1920px) {
  .km-size {
    padding: 0 195px;
  }
}
@media screen and (min-width: 1440px) and (max-width: 1919px) {
  .km-size {
    padding: 0 120px;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1439px) {
  .km-size {
    padding: 0 60px;
  }
}
@media screen and (min-width: 769px) and (max-width: 1023px) {
  .km-size {
    padding: 0 30px;
  }
}
@media screen and (max-width: 768px) {
  .km-size {
    padding: 0 10px;
  }
}
</style>
