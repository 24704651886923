<template>
  <div class="kl-menu" id="kl-menu">
    <kmSize class="kl-menu-fixed">
      <div class="kl-menu-wrap">
        <img
          class="kl-logo"
          src="https://ke-meng.oss-cn-hangzhou.aliyuncs.com/kelanbm-web/base/logo.png"
          alt=""
          @click="navigateTo({ path: '/index' })"
        />
        <span class="kl-span">健康白中国 健康白世界</span>
        <div class="kl-menu-list kl-menu-list-pc">
          <div class="kl-menu-wrap kl-menu-hover-wrap">
            <div
              class="kl-menu-item"
              :class="{ active: item.active }"
              v-for="(item, index) in menu"
              :key="index"
              @click="navigateTo(item)"
              @mouseenter="showSubMenu"
            >
              <span>{{ item.name }}</span>
            </div>
          </div>
        </div>
      </div>
    </kmSize>
    <!--pc 子目录显示-->
    <div
      class="kl-menu kl-sub-menu font-HarmonyOS_Sans_SC_Light"
      :class="{ subShow: subShow }"
    >
      <kmSize @mouseleave="closeSubMenu">
        <div class="kl-menu-wrap">
          <img
            class="kl-logo"
            src="https://ke-meng.oss-cn-hangzhou.aliyuncs.com/kelanbm-web/base/logo.png"
            alt=""
            @click="navigateTo({ path: '/index' })"
          />
          <div class="kl-menu-list kl-menu-list-pc">
            <div class="kl-menu-wrap">
              <div
                class="kl-menu-item"
                :class="{ active: item.active }"
                v-for="(item, index) in menu"
                :key="index"
              >
                <span @click="navigateTo(item)">{{ item.name }}</span>
                <div
                  class="kl-sub-menu-list"
                  v-if="item.children && item.children.length > 0"
                >
                  <p
                    v-for="(mtem, mindex) in item.children"
                    :key="mindex"
                    @click="navigateTo(mtem)"
                  >
                    {{ mtem.name }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </kmSize>
    </div>
    <!--h5显示-->
    <div class="kl-menu-icon" @click="openMenu">
      <i class="iconfont icon-menu"></i>
    </div>
    <!--h5显示-->
    <transition name="fade">
      <div
        class="kl-menu-list kl-menu-list-mobile"
        v-if="show"
        @click="closeMenu"
        @touchmove.prevent
      >
        <div
          class="kl-menu-wrap"
          :class="{ 'kl-menu-open': menuShow }"
          @click.stop
        >
          <div
            class="kl-menu-item"
            :class="{ active: item.active }"
            v-for="(item, index) in menu"
            :key="index"
            @click="navigateTo(item)"
          >
            <span>{{ item.name }}</span>
          </div>
        </div>
      </div>
    </transition>
    <div class="kl-search">中|EN <i class="iconfont icon-search"></i></div>
  </div>
</template>
<script>
import { scrollToTop } from '@/utils'
export default {
  data() {
    return {
      subShow: false,
      show: false,
      menuShow: false,
      menu: [
        // {
        //   name: '首页',
        //   id: 0,
        //   path: '/index',
        //   active: false,
        // },
        {
          name: '关于我们',
          id: 1,
          path: '/about',
          active: false,
          children: [
            {
              name: '公司简介',
              path: '/about',
              to: 'trod',
            },
            {
              name: '企业文化',
              path: '/about',
              to: 'culture',
            },
            {
              name: '发展历程',
              path: '/about',
              to: 'history',
            },
            {
              name: '',
              path: '',
              to: '',
            },
            {
              name: '',
              path: '',
              to: '',
            },
          ],
        },
        {
          name: '产品服务',
          id: 2,
          path: '/product',
          active: false,
          children: [
            {
              name: '技术领先',
              path: '/product/trod',
            },
            {
              name: '供应效率',
              path: '/product/supply',
            },
            {
              name: '品牌增效',
              path: '/product/odbm',
            },
            {
              name: '质量保障',
              path: '/product/quality',
            },
            {
              name: '',
              path: '',
              to: '',
            },
          ],
        },
        {
          name: '科研创新',
          id: 3,
          path: '/design',
          active: false,
          children: [
            {
              name: '珂蓝研究院',
              path: '/design/school',
            },
            {
              name: '科研团队',
              path: '/design/team',
            },
            {
              name: '成果转化',
              path: '/design/harvest',
            },
            {
              name: '',
              path: '',
              to: '',
            },
            {
              name: '',
              path: '',
              to: '',
            },
          ],
        },
        {
          name: '智能制造',
          id: 4,
          path: '/produce',
          active: false,
          children: [
            {
              name: '工厂介绍',
              path: '/produce/introd',
            },
            {
              name: '生产流程',
              path: '/produce/flow',
            },
            {
              name: '品质管理',
              path: '/produce/quality',
            },
            {
              name: '',
              path: '',
              to: '',
            },
            {
              name: '',
              path: '',
              to: '',
            },
          ],
        },
        {
          name: '新闻资讯',
          id: 5,
          path: '/news',
          active: false,
          children: [
            {
              name: '全球前沿资讯',
              path: '/news/industry',
            },
            {
              name: '行业新规',
              path: '/news/stipulate',
            },
            {
              name: '企业动态',
              path: '/news/enterprise',
            },
            {
              name: '员工风采',
              path: '/news/staff',
            },
            {
              name: '',
              path: '',
              to: '',
            },
          ],
        },
        {
          name: '加入珂蓝',
          id: 6,
          path: '/join',
          active: false,
          children: [
            {
              name: '珂蓝人才观',
              path: '/join',
              to: 'joinIndex',
            },
            {
              name: '选择珂蓝',
              path: '/join',
              to: 'elect',
            },
            {
              name: '福利关怀',
              path: '/join',
              to: 'welfare',
            },
            {
              name: '招聘信息',
              path: '/join',
              to: 'invite',
            },
            {
              name: '加入我们',
              path: '/join',
              to: 'join',
            },
          ],
        },
      ],
    }
  },
  watch: {
    $route: {
      handler(to) {
        this.menu.forEach((item) => {
          if (to.path.indexOf(item.path) >= 0) {
            item.active = true
          } else {
            item.active = false
          }
        })
      },
      immediate: true,
    },
  },
  methods: {
    showSubMenu() {
      this.subShow = true
    },
    closeSubMenu() {
      this.subShow = false
    },
    navigateTo(item) {
      let { path, to } = item
      if (path) {
        this.$router.push({ path, query: { to } })
        this.closeMenu()
        scrollToTop()
      }
    },
    openMenu() {
      this.show = true
      setTimeout(() => {
        this.menuShow = true
      }, 10)
    },
    closeMenu() {
      this.menuShow = false
      setTimeout(() => {
        this.show = false
      }, 0)
    },
  },
}
</script>
<style scoped lang="scss">
@import './pc.scss';
@import './ipad.scss';
@import './mobile.scss';
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
