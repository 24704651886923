<template>
  <div
    class="kl-tab-wrap"
    :class="{ 'kl-tab-border': border }"
    :style="{ margin: margin }"
  >
    <kmSize>
      <div class="kl-tab">
        <span
          v-for="(item, index) in list"
          :key="index"
          @click="navigateTo(item)"
          :class="{ active: routeName == item.name }"
          >{{ item.desc }}</span
        >
      </div>
    </kmSize>
  </div>
</template>
<script>
export default {
  props: {
    route: {
      type: Boolean,
      default: true,
    },
    list: {
      type: Array,
      default: () => [],
    },
    border: {
      type: Boolean,
      default: false,
    },
    margin: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      routeName: 'joinIndex',
    }
  },
  watch: {
    $route: {
      handler(to) {
        if (this.route) {
          console.log('routeName', this.routeName, to.name)
          this.routeName = to.name
        }
      },
      immediate: true,
    },
  },
  methods: {
    navigateTo(item) {
      if (item && item.path) {
        this.$router.push(item.path)
      } else {
        this.routeName = item.name
        this.$emit('tablClick', item)
      }
    },
  },
}
</script>
<style lang="scss" scoped>
@import './pc.scss';
@import './ipad.scss';
@import './mobile.scss';
.kl-tab-wrap {
  width: 100%;
  text-align: center;
  &.kl-tab-border {
    border-bottom: 1px solid #eeeeee;
  }
}
.kl-tab {
  display: flex;
  align-items: center;
  padding: 16px 0;
  span {
    cursor: pointer;
    position: relative;
    transition: all 0.3s;
    color: black;
    background-color: white;
    -webkit-tap-highlight-color: transparent;
    white-space: nowrap;
    &::before {
      content: '';
      position: absolute;
      width: 80%;
      height: 2px;
      bottom: -16px;
      left: 10%;
      background-color: #0e80d7;
      transform: scaleX(0);
      transition: transform 0.3s ease;
    }
    &:hover::before,
    &.active::before {
      transform: scaleX(1);
    }
    &.active {
      color: white;
      background: #0e80d7;
      border-radius: 16px;
      font-weight: 600;
    }
  }
}
</style>
