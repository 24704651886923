<template>
  <div class="kl-scroll-num" :class="font" ref="scrollNum"></div>
</template>
<script>
import { Flip } from 'number-flip'
export default {
  props: {
    // font-DIN-Medium font-096-CAI978
    font: {
      type: String,
      default: 'font-096-CAI978',
    },
    num: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      isRun: false,
      observe: null,
    }
  },
  beforeMount() {
    if (this.observe && this.$refs.scrollNum) {
      this.observe.unobserve(this.$refs.scrollNum)
      this.observe = null
    }
  },
  mounted() {
    let _this = this
    this.observe = new IntersectionObserver(
      (e) => {
        if (!this.isRun && e[0].isIntersecting) {
          this.isRun = true
          new Flip({
            node: this.$refs.scrollNum,
            from: 0, //开始的数
            to: _this.num, //结束的数
            duration: 2, //过度时间
            delay: 0.5, //等待时间
          })
        }
      },
      {
        threshold: [1],
      }
    )
    this.observe.observe(_this.$refs.scrollNum)
  },
}
</script>
<style lang="scss" scoped>
.kl-scroll-num {
  display: inline-block;
}
</style>
