import { Toast } from 'vant'
import 'vant/es/toast/style/index'
let loading
function startLoading(msg) {
  loading = Toast.loading({
    mask: false,
    duration: 0,
    forbidClick: true,
    loadingType: 'spinner',
    message: msg || '加载中...',
  })
}

function endLoading() {
  loading.clear()
}
let needLoadingRequestCount = 0

export function showFullScreenLoading(msg) {
  if (needLoadingRequestCount === 0) {
    startLoading(msg)
  }
  needLoadingRequestCount++
}

export function tryHideFullScreenLoading() {
  if (needLoadingRequestCount <= 0) return
  needLoadingRequestCount--
  if (needLoadingRequestCount === 0) {
    endLoading()
  }
}
