let BASE, wxApi, diagImg, wxAuthUrl
switch (process.env.VUE_APP_ENV) {
  case 'prod':
    BASE = 'https://api.km-union.com/'
    wxApi = 'http://web.km-union.com/'
    wxAuthUrl = 'http://web.km-union.com/weixin/auth'
    diagImg = 'https://km-diagns.oss-cn-hangzhou.aliyuncs.com/'
    break
  case 'uat':
    BASE = 'https://apiuat.km-union.com/'
    wxApi = 'http://webtest.km-union.com/'
    wxAuthUrl = 'http://web.km-union.com/weixin/auth'
    diagImg = 'http://km-diagns.oss-cn-hangzhou.aliyuncs.com/'
    break
  case 'dev':
    BASE = 'https://apiuat.km-union.com/'
    wxApi = 'http://webtest.km-union.com/'
    wxAuthUrl = 'http://web.km-union.com/weixin/auth'
    diagImg = 'https://km-diagns.oss-cn-hangzhou.aliyuncs.com/'
    break
}

export { BASE, wxApi, diagImg, wxAuthUrl }
