import {
  showFullScreenLoading,
  tryHideFullScreenLoading,
} from '@/utils/loading'

function http(url, params, method, setLoading, setOpenId) {
  return new Promise((resolve, reject) => {
    // let { url, method, params, setLoading, setOpenId } = config
    let openid = sessionStorage.getItem('openid')
    if (setLoading) {
      showFullScreenLoading()
    }
    let body = ''
    if (method === 'get') {
      let search = []
      Object.keys(params).forEach((key) => {
        search.push(key + '=' + params[key])
      })
      search = search.join('&')
      url = `${url}?${search}`
    } else if (method === 'post') {
      body = JSON.stringify(params)
    }
    let headers = {
      // 'Content-type': 'application/json;charset=UTF-8',
    }
    if (setOpenId) {
      headers.openid = openid
    }
    let options = {
      headers,
      method,
    }
    if (body) {
      options.body = body
    }
    fetch(url, options)
      .then((res) => {
        if (setLoading) {
          tryHideFullScreenLoading()
        }
        let { status } = res
        if (/^(2|3)\d{2}$/.test(status)) {
          let result = res.json()
          console.log('请求成功')
          return result
        } else {
          console.log('请求失败')
          return Promise.reject({ status })
        }
      })
      .then((res) => {
        console.log('成功回调', res)
        resolve(res)
      })
      .catch((error) => {
        console.log('error', error)
        reject(error)
      })
  })
}

export default http
