import { createRouter, createWebHashHistory } from 'vue-router'

const router = createRouter({
  history: createWebHashHistory(),
  routes: [
    {
      path: '/index',
      name: 'Index',
      component: () =>
        import(/* webpackChunkName: "Index" */ '../page/index/index.vue'),
    },
    {
      path: '/about',
      name: 'About',
      component: () =>
        import(/* webpackChunkName: "About" */ '../page/about/index.vue'),
    },
    {
      path: '/product',
      name: 'productIndex',
      component: () =>
        import(
          /* webpackChunkName: "productIndex" */ '../page/product/index.vue'
        ),
      redirect: '/product/trod',
      children: [
        {
          path: 'trod',
          name: 'productTrod',
          component: () =>
            import(
              /* webpackChunkName: "productTrod" */ '../page/product/trod/index'
            ),
        },
        {
          path: 'supply',
          name: 'productSupply',
          component: () =>
            import(
              /* webpackChunkName: "productSupply" */ '../page/product/supply/index'
            ),
        },
        {
          path: 'quality',
          name: 'productQuality',
          component: () =>
            import(
              /* webpackChunkName: "productQuality" */ '../page/product/quality/index'
            ),
        },
        {
          path: 'odbm',
          name: 'productOdbm',
          component: () =>
            import(
              /* webpackChunkName: "productOdbm" */ '../page/product/odbm/index'
            ),
        },
      ],
    },
    {
      path: '/design',
      name: 'designIndex',
      component: () =>
        import(
          /* webpackChunkName: "designIndex" */ '../page/design/index.vue'
        ),
      redirect: '/design/school',
      children: [
        {
          path: 'school',
          name: 'designSchool',
          component: () =>
            import(
              /* webpackChunkName: "designSchool" */ '../page/design/school/index'
            ),
        },
        {
          path: 'team',
          name: 'designTeam',
          component: () =>
            import(
              /* webpackChunkName: "designTeam" */ '../page/design/team/index'
            ),
        },
        {
          path: 'harvest',
          name: 'designHarvest',
          component: () =>
            import(
              /* webpackChunkName: "designHarvest" */ '../page/design/harvest/index'
            ),
        },
      ],
    },
    {
      path: '/produce',
      name: 'produceIndex',
      component: () =>
        import(
          /* webpackChunkName: "designIndex" */ '../page/produce/index.vue'
        ),
      redirect: '/produce/introd',
      children: [
        {
          path: 'introd',
          name: 'introd',
          component: () =>
            import(
              /* webpackChunkName: "designSchool" */ '../page/produce/introd/index'
            ),
        },
        {
          path: 'flow',
          name: 'flow',
          component: () =>
            import(
              /* webpackChunkName: "designSchool" */ '../page/produce/flow/index'
            ),
        },
        {
          path: 'quality',
          name: 'quality',
          component: () =>
            import(
              /* webpackChunkName: "designSchool" */ '../page/produce/quality/index'
            ),
        },
      ],
    },
    {
      path: '/news',
      name: 'newsIndex',
      component: () =>
        import(/* webpackChunkName: "newsIndex" */ '../page/news/index.vue'),
      redirect: '/news/industry',
      children: [
        {
          path: 'industry',
          name: 'newsIndustry',
          component: () =>
            import(
              /* webpackChunkName: "newsIndustry" */ '../page/news/industry/index'
            ),
        },
        {
          path: 'stipulate',
          name: 'newsStipulate',
          component: () =>
            import(
              /* webpackChunkName: "newsIndustry" */ '../page/news/stipulate/index'
            ),
        },
        {
          path: 'enterprise',
          name: 'newsEnterprise',
          component: () =>
            import(
              /* webpackChunkName: "newsEnterprise" */ '../page/news/enterprise/index'
            ),
        },
        {
          path: 'staff',
          name: 'newsStaff',
          component: () =>
            import(
              /* webpackChunkName: "newsStaff" */ '../page/news/staff/index'
            ),
        },
        {
          path: 'detail',
          name: 'newsDetail',
          component: () =>
            import(
              /* webpackChunkName: "newsStaff" */ '../page/news/detail/index'
            ),
        },
      ],
    },
    {
      path: '/join',
      name: 'joinIndex',
      component: () =>
        import(/* webpackChunkName: "joinIndex" */ '../page/join/index.vue'),
    },
    {
      path: '/',
      redirect: '/index',
    },
  ],
})

export default router
